import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../contexts/alert/AlertContext";
import {
  fetchConversationById,
  sendConversationMessage,
  submitMessageFeedback,
} from "../pages/RHSCAssistant/apis";
import { updateConversationId, updateLastRHSCPageVisit } from "../utils";
import { useAuthContext } from "../contexts/authentication/AuthenticationContext";
import moment from "moment";

interface IUserMessage {
  message: string;
  isUserMessage: boolean;
}

export interface IBotMessage {
  isUserMessage: boolean;
  messageID: string;
  message: string;
  feedback: {
    option: string;
    comment: string;
  };
  sources: {
    [key: string]: string;
  };
  otherLinks: {
    [key: string]: string;
  };
  shouldStream: boolean;
}

export type ConversationType = IUserMessage | IBotMessage;

export default function useConversation({ id }: { id: string }) {
  const { user } = useAuthContext();
  const alert = useContext(AlertContext);
  const [conversation, setConversation] = useState<ConversationType[]>([]);
  const [conversationID, setConversationID] = useState<string>(id);
  const [isMessageSending, setIsMessageSending] = useState<boolean>(false);
  const [isConversationLoading, setIsConversationLoading] =
    useState<boolean>(false);
  const [isSubmittingFeedback, setIsSubmittingFeedback] =
    useState<boolean>(false);
  const [submittingFeedbackFor, setSubmittingFeedbackFor] =
    useState<string>(null);

  const getParams = () => {
    let params = new URLSearchParams();
    params.append("userEmail", user?.email);
    params.append("conversationID", id);

    return params;
  };

  function generateTimestamp() {
    // Get the current time with moment
    const now = moment();

    // Format the date and time
    let formattedDate = now.format("YYYY-MM-DDTHH:mm:ss.SSS");

    // Get the timezone offset in hours and minutes
    const offset = now.utcOffset();
    const offsetHours = Math.floor(Math.abs(offset) / 60);
    const offsetMinutes = Math.abs(offset) % 60;
    const formattedOffset =
      (offset >= 0 ? "+" : "-") +
      String(offsetHours).padStart(2, "0") +
      ":" +
      String(offsetMinutes).padStart(2, "0");

    return `${formattedDate}${formattedOffset}`;
  }

  useEffect(() => {
    if (id.length) {
      setIsConversationLoading(true);
      fetchConversationById({
        endpoint: "/conversation",
        params: getParams(),
        successCallback: (data) => {
          const _conversation = data.response.flatMap(
            ({
              question,
              answer,
              createdAt,
              feedback,
              messageID,
              otherLinks,
              sources,
            }) => [
              { message: question, isUserMessage: true },
              {
                message: answer,
                isUserMessage: false,
                feedback,
                messageID,
                otherLinks,
                sources,
                shouldStream: false,
              },
            ]
          );

          setConversation(_conversation);
          setIsConversationLoading(false);
        },
        failureCallback: ({ response }) => {
          alert.addAlert(
            response.status === 500
              ? "The backend service is down"
              : response?.data || "Something went wrong",
            "danger"
          );
          setIsConversationLoading(false);
        },
      });
    }
  }, [id]);

  const handleSendMessage = (input: string) => {
    setIsMessageSending(true);
    const userMessage: IUserMessage = {
      message: input,
      isUserMessage: true,
    };

    setConversation((prevConversation) => [...prevConversation, userMessage]);

    sendConversationMessage({
      endpoint:
        conversationID?.length === 0 ? "/conversation/new" : "/conversation",
      dataToSend: {
        conversationID: conversationID,
        messageID: "",
        timestamp: generateTimestamp(),
        userInfo: {
          userEmail: user?.email,
          userName: user?.name,
        },
        question: input,
        feedback: {
          option: "",
          comment: "",
        },
        newConversation: conversationID?.length === 0,
      },
      successCallback: (data) => {
        const botMessage: IBotMessage = {
          isUserMessage: false,
          messageID: data?.messageID,
          message: data?.response.answer,
          sources: data?.response.sources,
          otherLinks: data?.otherLinks,
          feedback: data?.feedback || { option: "", comment: "" },
          shouldStream: true,
        };

        setConversation((prevConversation) => [
          ...prevConversation,
          botMessage,
        ]);

        setIsMessageSending(false);

        if (conversationID?.length === 0) {
          setConversationID(data?.conversationID);
          updateConversationId(data?.conversationID);
          updateLastRHSCPageVisit(
            `/rhsc-assistant/conversations/${data?.conversationID}`
          );
        }
      },
      failureCallback: ({ response }) => {
        alert.addAlert(
          response.status === 500
            ? "The backend service is down"
            : response?.data || "Something went wrong",
          "danger"
        );
        setIsMessageSending(false);
      },
    });
  };

  const handleFeedbackIconClick = (
    messageId: string,
    type: string,
    comment: string
  ) => {
    setIsSubmittingFeedback(true);
    setSubmittingFeedbackFor(messageId);

    submitMessageFeedback({
      endpoint: "/feedback",
      dataToSend: {
        conversationID: conversationID,
        messageID: messageId,
        timestamp: generateTimestamp(),
        userInfo: {
          userEmail: "",
          userName: "",
        },
        question: "",
        feedback: {
          option: type,
          comment: comment,
        },
        newConversation: false,
      },
      successCallback: (data) => {
        alert.addAlert("Feedback submitted.", "success");
        const updatedConversations = conversation.map((message) => {
          if (!message.isUserMessage && message["messageID"] === messageId) {
            return {
              ...message,
              feedback: {
                option: type,
                comment: comment,
              },
              otherLinks: data.otherLinks,
            };
          }
          return message;
        });

        setIsSubmittingFeedback(false);
        setSubmittingFeedbackFor(null);
        setConversation(updatedConversations);
      },
      failureCallback: ({ response }) => {
        setIsSubmittingFeedback(false);
        setSubmittingFeedbackFor(null);

        alert.addAlert(
          response.status === 500
            ? "The backend service is down"
            : response?.data || "Something went wrong",
          "danger"
        );
      },
    });
  };

  const resetToNewConversation = () => {
    setConversation([]);
    setConversationID("");
    updateConversationId(null);
  };

  return {
    conversation,
    isMessageSending,
    isConversationLoading,
    isSubmittingFeedback,
    submittingFeedbackFor,
    handleSendMessage,
    handleFeedbackIconClick,
    resetToNewConversation,
  };
}
