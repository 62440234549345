import {
  OutlinedThumbsDownIcon,
  ThumbsDownIcon,
  OutlinedThumbsUpIcon,
  ThumbsUpIcon,
} from "@patternfly/react-icons";
import "./style.scss";
import { Button, Spinner, TextArea, Tooltip } from "@patternfly/react-core";
import { useEffect, useRef, useState } from "react";

const BotMessage = ({
  children: { message, messageID, feedback, sources, otherLinks, shouldStream },
  handleFeedbackIconClick,
  isSubmittingFeedback,
  submittingFeedbackFor,
  chatContainerRef,
}) => {
  const [showFeedbackInput, setShowFeedbackInput] = useState<boolean>(false);
  const [feedbackComment, setFeedbackComment] = useState<string>("");
  const [content, setContent] = useState("");
  const [index, setIndex] = useState(0);
  const [isStreamingComplete, setIsStreamingComplete] = useState(!shouldStream);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  let scrollTimeout = useRef(null);

  useEffect(() => {
    if (!message || message.length === 0) return;

    const chunkSize = 5;

    const intervalId = setInterval(() => {
      setContent(
        (prevContent) => prevContent + message.slice(index, index + chunkSize)
      );
      setIndex((prevIndex) => prevIndex + chunkSize);
      if (index + chunkSize >= message.length) {
        clearInterval(intervalId);
        setIsStreamingComplete(true);
      }
    }, 50);

    return () => clearInterval(intervalId);
  }, [message, index]);

  // const handleCopy = () => {
  //   navigator.clipboard.writeText(message);
  //   setShowCopyIcon(true);
  //   setTimeout(() => {
  //     setShowCopyIcon(false);
  //   }, 1500);
  // };

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          chatContainerRef.current;
        if (scrollHeight - scrollTop <= clientHeight + 10) {
          setIsAutoScrollEnabled(true);
        } else {
          setIsAutoScrollEnabled(false);
        }

        setIsUserScrolling(true);
        clearTimeout(scrollTimeout.current);
        scrollTimeout.current = setTimeout(() => {
          setIsUserScrolling(false);
        }, 100);
      }
    };

    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (shouldStream && chatContainerRef.current && isAutoScrollEnabled) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [content, shouldStream, isAutoScrollEnabled]);

  useEffect(() => {
    if (isStreamingComplete) {
      setIsAutoScrollEnabled(true);
    }
  }, [isStreamingComplete]);

  const onClickFeedback = (type: string) => {
    if (type === "negative") {
      setShowFeedbackInput(!showFeedbackInput);
    } else {
      setShowFeedbackInput(false);
      handleFeedbackIconClick(messageID, "positive", "");
    }
  };

  function submitNegativeFeedback() {
    handleFeedbackIconClick(messageID, "negative", feedbackComment);
    setShowFeedbackInput(!showFeedbackInput);
  }

  const sanitizeMessage = (message: string) => {
    const formattedMessage = message
      ?.replace(/\n/g, "<br />")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/```(.*?)```/gs, "<code>$1</code>")
      .replace(/`(.*?)`/g, "<code>$1</code>");

    return { __html: formattedMessage };
  };

  if (message)
    return (
      <div className="bot-message-wrapper">
        <div className="bot-icon-wrapper">
          <img
            src="/images/talk-bubble.png"
            alt="chat bubble"
            className="bot-icon"
          />
        </div>
        <div>
          {shouldStream ? (
            <div dangerouslySetInnerHTML={sanitizeMessage(content)} />
          ) : (
            <div dangerouslySetInnerHTML={sanitizeMessage(message)} />
          )}

          {isStreamingComplete && (
            <div className="source-link-wrapper">
              {sources
                ? Object.entries(sources).map(([anchorText, href]) => (
                    <p key={anchorText}>
                      <a
                        href={href as string}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {anchorText}
                      </a>
                    </p>
                  ))
                : null}
              {otherLinks
                ? Object.entries(otherLinks).map(([anchorText, href]) => (
                    <p key={anchorText}>
                      <a
                        href={href as string}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {anchorText}
                      </a>
                    </p>
                  ))
                : null}
            </div>
          )}

          {isStreamingComplete && (
            <div className="bot-message-action-icons-wrapper">
              {feedback?.option.length ? (
                feedback.option === "positive" ? (
                  <>
                    <ThumbsUpIcon
                      className="bot-message-action-icon-disabled"
                      disabled
                    />
                    <OutlinedThumbsDownIcon
                      className="bot-message-action-icon-disabled"
                      disabled
                    />
                  </>
                ) : (
                  <>
                    <OutlinedThumbsUpIcon
                      className="bot-message-action-icon-disabled"
                      disabled
                    />
                    <ThumbsDownIcon
                      className="bot-message-action-icon-disabled"
                      disabled
                    />
                  </>
                )
              ) : isSubmittingFeedback &&
                messageID === submittingFeedbackFor ? (
                <Spinner size="sm" />
              ) : (
                <>
                  <Tooltip content={<div>Good response</div>}>
                    <OutlinedThumbsUpIcon
                      onClick={() => onClickFeedback("positive")}
                      className="bot-message-action-icon"
                    />
                  </Tooltip>
                  <Tooltip content={<div>Bad response</div>}>
                    <OutlinedThumbsDownIcon
                      onClick={() => onClickFeedback("negative")}
                      className="bot-message-action-icon"
                    />
                  </Tooltip>
                </>
              )}

              {/* <Tooltip content={<div>Copy</div>}>
              {showCopyIcon ? (
                <CopyIcon />
              ) : (
                <OutlinedCopyIcon
                  onClick={handleCopy}
                  className="bot-message-action-icon"
                />
              )}
            </Tooltip> */}
            </div>
          )}
          {showFeedbackInput ? (
            <div className="bot-message-feedback-form">
              <TextArea
                value={feedbackComment}
                placeholder="Provide detailed feedback(optional)"
                onChange={(_event, value) => setFeedbackComment(value)}
                aria-label="feedback comment"
                resizeOrientation="vertical"
              />
              <Button
                variant="primary"
                size="sm"
                onClick={submitNegativeFeedback}
              >
                Submit
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    );
};

export default BotMessage;
