import rhscAssistantApiClient from "../../apis/rhsc-assistant/api-client";

interface RequestProps {
  endpoint: string;
  dataToSend?: any;
  successCallback?: (data) => void;
  failureCallback?: (data) => void;
  params?: any;
}

const sendConversationMessage = async (props: RequestProps) => {
  const { endpoint, dataToSend, successCallback, failureCallback } = props;
  const response = await rhscAssistantApiClient.post(endpoint, dataToSend);

  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

const submitMessageFeedback = async (props: RequestProps) => {
  const { endpoint, dataToSend, successCallback, failureCallback } = props;
  const response = await rhscAssistantApiClient.post(endpoint, dataToSend);

  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

const fetchConversations = async (props: RequestProps) => {
  const { endpoint, successCallback, failureCallback, params } = props;
  const response = await rhscAssistantApiClient.get(endpoint, { params });

  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

const fetchConversationById = async (props: RequestProps) => {
  const { endpoint, successCallback, failureCallback, params } = props;
  const response = await rhscAssistantApiClient.get(endpoint, { params });

  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

const submitVoFFeedback = async (props: RequestProps) => {
  const { endpoint, dataToSend, successCallback, failureCallback } = props;
  const response = await rhscAssistantApiClient.post(endpoint, dataToSend);

  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

export {
  sendConversationMessage,
  submitMessageFeedback,
  fetchConversations,
  fetchConversationById,
  submitVoFFeedback,
};
